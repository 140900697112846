import { Injectable, NgZone } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Observable } from 'rxjs';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Injector } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { Router } from '@angular/router';

@Injectable()
export class ErpSignalRService extends AppComponentBase {
    private hubConnection: signalR.HubConnection;

    constructor(
        injector: Injector,
        public _zone: NgZone,
        private _router: Router
    ) {
        super(injector);

        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(AppConsts.remoteServiceBaseUrl + '/signalr-erp') // SignalR hub URL
            .build();
    }

    startConnection() {
        return new Observable<void>((observer) => {
            if (this.hubConnection.state === signalR.HubConnectionState.Disconnected) {
                this.hubConnection
                    .start()
                    .then(() => {
                        console.log('Connection established with SignalR hub');
                        observer.next();
                        observer.complete();
                    })
                    .catch((error) => {
                        console.error('Error connecting to SignalR hub:', error);
                        observer.error(error);
                    });
            } else {
                console.log('HubConnection is not in the Disconnected state.');
            }
        });
    }

    endConnection() {
        this.hubConnection.off('ProcessPostSync');
        this.hubConnection.off('ProcessCheckSync');
        this.hubConnection.off('ReceiveNotification');
    }

    receiveNotification() {
        this.hubConnection.on('ReceiveNotification', (user, clientId, subdomain, routerLink) => {
            if (this.appSession.tenancyName === subdomain && this._router.url === routerLink && user !== this.appSession.getShownLoginName()) {
                abp.event.trigger("app.handleUnsavedChanges", user, this.appSession.getShownLoginName(), false);
            }
        });
    }

    processPostSync() {
        this.hubConnection.on('ProcessPostSync', (user, clientId, subdomain, routerLink) => {
            if (this.appSession.tenancyName === subdomain && this._router.url === routerLink) {
                if (user !== this.appSession.getShownLoginName()) {
                    abp.event.trigger("app.updateSync");
                    abp.notify.info(this.l("SyncMade", user));
                } else {
                    abp.notify.info(this.l("SyncingNewData"));
                }
            }
        });
    }

    processCheckSync() {
        this.hubConnection.on('ProcessCheckSync', (user, clientId, subdomain, routerLink, hasChanges) => {
            if (this.appSession.tenancyName === subdomain && this._router.url === routerLink) {
                if (user !== this.appSession.getShownLoginName()) {
                    abp.event.trigger("app.disableSync");
                }

                if (hasChanges) {
                    abp.event.trigger("app.handleUnsavedChanges", user, this.appSession.getShownLoginName(), true);
                }
            }
        });
    }

    notifyUsers(): void {
        this.hubConnection.invoke('NotifyUsers', this.appSession.getShownLoginName(), this.appSession.selectedClientId, this.appSession.tenancyName, this._router.url);
    }

    postSyncCall(): void {
        this.hubConnection.invoke('HandlePostSyncCall', this.appSession.getShownLoginName(), this.appSession.selectedClientId, this.appSession.tenancyName, this._router.url);
    }

    checkSyncCompleteCall(hasChanges: boolean, routerURL: string): void {
        this.hubConnection.invoke('HandleCheckSyncCompleteCall', this.appSession.getShownLoginName(), this.appSession.selectedClientId, this.appSession.tenancyName, routerURL, hasChanges);
    }
}