import { AbpMultiTenancyService } from 'abp-ng2-module';
import { Injectable } from '@angular/core';
import { ApplicationInfoDto, GetCurrentLoginInformationsOutput, SessionServiceProxy, TenantLoginInfoDto, UserLoginInfoDto, UiCustomizationSettingsDto, DynamicPermissionServiceProxy, GuidKeyValuePairDto, DynamicPageStateDto, StreamlineaSettingsServiceProxy, DynamicDataServiceProxy, SaveDynamicDataRequest } from '@shared/service-proxies/service-proxies';
import { of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Injectable()
export class AppSessionService {

    private _user: UserLoginInfoDto;
    private _impersonatorUser: UserLoginInfoDto;
    private _tenant: TenantLoginInfoDto;
    private _impersonatorTenant: TenantLoginInfoDto;
    private _application: ApplicationInfoDto;
    private _theme: UiCustomizationSettingsDto;
    private _dynamicPermissionNames: string[];
    private _sessionToken: string;
    private _statusCodeDescriptions: any[] = [];
    private _stepNumber: number = -1;
    private _intacctStepNumber: number = -1;
    public legalEntities: GuidKeyValuePairDto[] = [];
    public legalEntitiesWithoutAllEntities: GuidKeyValuePairDto[] = [];
    private _selectedClientId: string;
    private _selectedLocationId: string = "00000000-0000-0000-0000-000000000000";
    private _completedBankMaintenance: any = null;
    private _completedClientMaintenance: any = null;
    private _completedEntityMaintenance: any = null;
    private _entityMaintenanceChanged = false;
    private _connectionVerified = false;
    private _emptyUserRights = false;
    private _emptyClientRights = false;
    private _emptyEntityRights = false;
    private _completedUserMaintenance: any = null;
    public dynamicPageStates: DynamicPageStateDto[] = [];
    public hideEntityColumn: boolean;

    constructor(
        private _sessionService: SessionServiceProxy,
        private _abpMultiTenancyService: AbpMultiTenancyService,
        private _dynamicDataService: DynamicDataServiceProxy,
        private _dynamicPermission: DynamicPermissionServiceProxy,
        private _streamlineaSettings: StreamlineaSettingsServiceProxy) {
    }

    get selectedClientId(): string {
        return this._selectedClientId === undefined ? this.legalEntities[0]?.id : this._selectedClientId;
    }

    set selectedClientId(val: string) {
        this._selectedClientId = val;
    }

    get selectedLocationId(): string {
        return this._selectedLocationId === undefined ? "00000000-0000-0000-0000-000000000000" : this._selectedLocationId;
    }

    set selectedLocationId(val: string) {
        this._selectedLocationId = val;
    }

    get stepNumber() {
        return this._stepNumber;
    }

    set stepNumber(val: number) {
        this._stepNumber = val;
    }

    get intacctStepNumber() {
        return this._intacctStepNumber;
    }

    set intacctStepNumber(val: number) {
        this._intacctStepNumber = val;
    }

    get application(): ApplicationInfoDto {
        return this._application;
    }

    set application(val: ApplicationInfoDto) {
        this._application = val;
    }

    get user(): UserLoginInfoDto {
        return this._user;
    }

    get userId(): number {
        return this.user ? this.user.id : null;
    }

    get tenant(): TenantLoginInfoDto {
        return this._tenant;
    }

    get tenancyName(): string {
        return this._tenant ? this.tenant.tenancyName : '';
    }

    get tenantId(): number {
        return this.tenant ? this.tenant.id : null;
    }

    get sessionToken(): string {
        return this._sessionToken;
    }

    getSessionToken(): void {
        this._streamlineaSettings.getSessionToken().subscribe(sessionToken => {
            this._sessionToken = sessionToken;
        });
    }

    getShownLoginName(): string {
        this._dynamicPermission.getUserDynamicPermissions().subscribe(permissionNames => {
            this._dynamicPermissionNames = permissionNames;
        });

        const userName = this._user.userName;
        if (!this._abpMultiTenancyService.isEnabled) {
            return userName;
        }

        return (this._tenant ? this._tenant.tenancyName : '.') + '\\' + userName;
    }

    get theme(): UiCustomizationSettingsDto {
        return this._theme;
    }

    set theme(val: UiCustomizationSettingsDto) {
        this._theme = val;
    }

    get dynamicPermissionNames(): string[] {
        return this._dynamicPermissionNames;
    }

    set dynamicPermissionNames(val: string[]) {
        this._dynamicPermissionNames = val;
    }

    get statusCodeDescriptions(): string[] {
        return this._statusCodeDescriptions;
    }

    set statusCodeDescriptions(val: string[]) {
        this._statusCodeDescriptions = val;
    }

    get completedBankMaintenance(): any {
        return this._completedBankMaintenance;
    }

    set completedBankMaintenance(val: any) {
        this._completedBankMaintenance = val;
    }

    get completedClientMaintenance(): any {
        return this._completedClientMaintenance;
    }

    set completedClientMaintenance(val: any) {
        this._completedClientMaintenance = val;
    }

    get completedEntityMaintenance(): any {
        return this._completedEntityMaintenance;
    }

    set completedEntityMaintenance(val: any) {
        this._completedEntityMaintenance = val;
    }

    get entityMaintenanceChanged(): any {
        return this._entityMaintenanceChanged;
    }

    set entityMaintenanceChanged(val: any) {
        this._entityMaintenanceChanged = val;
    }

    get emptyUserRights(): any {
        return this._emptyUserRights;
    }

    set emptyUserRights(val: any) {
        this._emptyUserRights = val;
    }

    get emptyClientRights(): any {
        return this._emptyClientRights;
    }

    set emptyClientRights(val: any) {
        this._emptyClientRights = val;
    }

    get emptyEntityRights(): any {
        return this._emptyEntityRights;
    }

    set emptyEntityRights(val: any) {
        this._emptyEntityRights = val;
    }

    get connectionVerified(): any {
        return this._connectionVerified;
    }

    set connectionVerified(val: any) {
        this._connectionVerified = val;
    }

    get completedUserMaintenance(): any {
        return this._completedUserMaintenance;
    }

    set completedUserMaintenance(val: any) {
        this._completedUserMaintenance = val;
    }

    init(): Promise<UiCustomizationSettingsDto> {
        return new Promise<UiCustomizationSettingsDto>((resolve, reject) => {
            this._sessionService.getCurrentLoginInformations().toPromise().then((result: GetCurrentLoginInformationsOutput) => {
                this._application = result.application;
                this._user = result.user;
                this._tenant = result.tenant;
                this._theme = result.theme;
                this._impersonatorTenant = result.impersonatorTenant;
                this._impersonatorUser = result.impersonatorUser;
                this._completedBankMaintenance = result.completedBankMaintenance;
                this._completedClientMaintenance = result.completedClientMaintenance;
                this._completedEntityMaintenance = result.completedEntityMaintenance;
                this._completedUserMaintenance = result.completedUserMaintenance;
                this.legalEntities = result.legalEntities;
                this.legalEntitiesWithoutAllEntities = result.legalEntitiesWithoutAllEntities;
                this.selectedClientId = result.savedClientAndLocationFilter?.clientIdGuid;
                this.selectedLocationId = result.savedClientAndLocationFilter?.locationIdGuid;
                this.dynamicPageStates = result.dynamicPageState;
                this.hideEntityColumn = result.hideEntityColumn;

                resolve(result.theme);
            }, (err) => {
                reject(err);
            });
        });
    }

    refreshPostSignup() {
        let request = new SaveDynamicDataRequest();
        let data = {};
        request.action = "update";
        request.dataSourceName = "PostSignupProcessCheck";
        request.data = data;

        this._dynamicDataService.saveDynamicData(request)
            .pipe(
                catchError(() => {
                    return of(undefined);
                }),
                finalize(() => {
                })
            ).subscribe(result => {
                if (result !== undefined) {
                    var postSignupResult = result.data.items[0].response;

                    if (this._completedBankMaintenance !== postSignupResult.completedBankMaintenance ||
                        this._completedClientMaintenance !== postSignupResult.completedClientMaintenance ||
                        this._completedEntityMaintenance !== postSignupResult.completedEntityMaintenance ||
                        this._completedUserMaintenance !== postSignupResult.completedUserMaintenance) {

                        if (this._completedEntityMaintenance !== postSignupResult.completedEntityMaintenance) {
                            this._entityMaintenanceChanged = true;
                        }
                        else {
                            this._entityMaintenanceChanged = false;
                        }

                        this._completedBankMaintenance = postSignupResult.completedBankMaintenance;
                        this._completedClientMaintenance = postSignupResult.completedClientMaintenance;
                        this._completedEntityMaintenance = postSignupResult.completedEntityMaintenance;
                        this._completedUserMaintenance = postSignupResult.completedUserMaintenance;

                        abp.event.trigger('app.updateMainMenu');
                    }
                }
            });
    }

    changeTenantIfNeeded(tenantId?: number): boolean {
        if (this.isCurrentTenant(tenantId)) {
            return false;
        }

        abp.multiTenancy.setTenantIdCookie(tenantId);
        location.reload();
        return true;
    }

    private isCurrentTenant(tenantId?: number) {
        let isTenant = tenantId > 0;

        if (!isTenant && !this.tenant) { // this is host
            return true;
        }

        if (!tenantId && this.tenant) {
            return false;
        } else if (tenantId && (!this.tenant || this.tenant.id !== tenantId)) {
            return false;
        }

        return true;
    }

    get impersonatorUser(): UserLoginInfoDto {
        return this._impersonatorUser;
    }
    get impersonatorUserId(): number {
        return this.impersonatorUser ? this.impersonatorUser.id : null;
    }
    get impersonatorTenant(): TenantLoginInfoDto {
        return this._impersonatorTenant;
    }
    get impersonatorTenancyName(): string {
        return this.impersonatorTenant ? this.impersonatorTenant.tenancyName : '';
    }
    get impersonatorTenantId(): number {
        return this.impersonatorTenant ? this.impersonatorTenant.id : null;
    }
}
