import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component,
    ComponentRef, EventEmitter, Injector, Input, Output,
    QueryList, TemplateRef, ViewChild, ViewChildren
} from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { merge as _merge } from 'lodash-es';
import { DateTime } from 'luxon';
import { FormGroup } from '@angular/forms';
import { CustomTemplate } from '@app/shared/common/dynamic-components/dynamic-grid/custom-template';
import { DynamicGridComponent } from '@app/shared/common/dynamic-components/dynamic-grid/dynamic-grid.component';
import { DynamicFormComponent } from '@app/shared/common/dynamic-components/dynamic-form/dynamic-form.component';
import { DynamicActionButtonComponent } from '../dynamic-action-button/dynamic-action-button.component';
import { DashboardCustomizationServiceProxy, DashboardOutput } from '@shared/service-proxies/service-proxies';
import { WidgetViewDefinition } from '@app/shared/common/customizable-dashboard/definitions';
import { DashboardViewConfigurationService } from '@app/shared/common/customizable-dashboard/dashboard-view-configuration.service';
import { GridsterConfig } from 'angular-gridster2';
import * as rtlDetect from 'rtl-detect';
import { ActivatedRoute } from '@angular/router';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { gridTypes } from 'angular-gridster2/lib/gridsterConfig.interface';
import { DynamicComponent } from 'ng-dynamic-component';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
    selector: 'dynamic-component',
    templateUrl: './dynamic-component.component.html',
    styleUrls: ['./dynamic-component.component.scss'],
    animations: [appModuleAnimation()],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicComponentComponent extends AppComponentBase {
    @ViewChildren(DynamicComponent) dynamicComponents: QueryList<DynamicComponent>;
    @ViewChildren('dynamicGrid') dynamicGridComponents: QueryList<DynamicGridComponent>;
    @ViewChildren('dynamicForm') dynamicFormComponents: QueryList<DynamicFormComponent>;
    @ViewChildren('dynamicActionButton') dynamicActionButtonComponents: QueryList<DynamicActionButtonComponent>;

    @Input() masterPageId: string = "";
    @Input() masterPageLabelKey: string = "";
    @Input() isDetailPage: boolean = false;
    @Input() formIdentifier: string = "";
    @Input() gridStructures: any;
    @Input() formStructures: any;
    @Input() actionButtonStructures: any;
    @Input() gridsData: any;
    @Input() formsData: any;
    @Input() pageName: string;
    @Input() pageId: string;
    @Input() useParentComponentData: boolean = false;
    @Input() parentItem: any;

    @Input() fieldLabelWidth: number = 0;
    @Input() fieldControlWidth: number = 0;
    @Input() groupFieldSetWidth: number = 0;
    @Input() groupFieldSetGap: number = 0;
    @Input() showBorder: boolean = false;
    @Input() showGuide: boolean = false;

    @Output() getClientIdAndErpIdEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() getDynamicPageLabelKey: EventEmitter<string> = new EventEmitter<string>();

    gridName: string;
    gridStructure: any;
    formStructure: any;
    actionButtonStructure: any;
    selectedGridType: gridTypes = 'verticalFixed';
    margin: number = 15;
    fixedColWidth: number = 45;
    fixedRowHeight: number = 45;
    rowHeightRatio: number = 1;
    dataAsOf: DateTime;
    selectedItemIds: string[] = [];
    editedRowIndex: number;
    customTemplates: CustomTemplate[] = [];
    @ViewChild('customBillNumber') public customBillNumber: TemplateRef<any>;
    @ViewChild('customPayCheckBox') public customPayCheckBox: TemplateRef<any>;
    @ViewChild('customMenuIcon') public customMenuIcon: TemplateRef<any>;
    @ViewChild('dashboardTabs') dashboardTabs: TabsetComponent;
    formGroup: FormGroup;
    gridComponents: any[] = [];
    formComponents: any[] = [];
    actionButtonComponents: any[] = [];
    tabComponents: any[] = [];
    retrieveDataBy: string;
    pageLayout: any;
    widgetViewDefinitions: WidgetViewDefinition[] = [];
    list: any[] = [];
    loading = true;
    busy = true;
    useStepper = false;
    isPublic = false;
    componentList: any[] = [];
    dashboardDefinition: DashboardOutput;
    userDashboard: any;
    dashboardName: string;
    options: GridsterConfig[] = [];
    current = 0;
    pageLabelKey: string;
    gridNameByComponent: { [id: string]: string } = {};
    savedChangesInTabs: { [id: string]: any[] } = {};
    changesInTabs: { [id: string]: any[] } = {};
    currentUpdated: boolean = false;
    setToTrue: boolean = false;

    steps = [];

    selectedPage = {
        idx: 0,
        id: '',
        name: ''
    };

    constructor(
        injector: Injector,
        private cd: ChangeDetectorRef,
        private _activatedRoute: ActivatedRoute,
        private _dashboardViewConfiguration: DashboardViewConfigurationService,
        private _dashboardCustomizationServiceProxy: DashboardCustomizationServiceProxy
    ) {
        super(injector);
    }

    onFieldLabelWidthValueChange(value: any): void {
        this.fieldLabelWidth = value;
        this.cd.markForCheck();
    }

    onFieldControlWidthValueChange(value: any): void {
        this.fieldControlWidth = value;
        this.cd.markForCheck();
    }

    onGroupFieldSetWidthValueChange(value: any): void {
        this.groupFieldSetWidth = value;
        this.cd.markForCheck();
    }

    onGridGapValueChange(value: any): void {
        this.groupFieldSetGap = value;
        this.cd.markForCheck();
    }

    refreshStructureToLoadData(clientId: number, erpId: number) {
        if (clientId !== 0 || erpId !== 0) {
            this.clientAndErpService.isDataSimulation = true;
            this.retrieveComponents();

            this.getUserDashboard(this.selectedPage.id, true);
        }
    }

    buildStepper() {
        for (var [index, tabComponent] of this.tabComponents.entries()) {
            this.steps.push({ label: tabComponent.tabName, disabled: this.appSession.stepNumber > -1 ? this.appSession.stepNumber < index : index > 0 });
        }
    }

    ngOnInit() {
        if (this.appSession.stepNumber > -1) {
            this.current = this.appSession.stepNumber;
        }

        if (this._activatedRoute.snapshot["_urlSegment"].segments[0].path !== 'app' && this._activatedRoute.snapshot["_urlSegment"].segments[1].path !== 'main') {
            this.pageId = this.convertKebabCaseToPascalCase(this._activatedRoute.snapshot["_urlSegment"].segments[1]?.path);
        }
        else {
            this.pageId = this.convertKebabCaseToPascalCase(this._activatedRoute.snapshot["_urlSegment"].segments[3]?.path);
        }
        this.dashboardName = this.pageId + "Dashboard";

        if (this.itemsStateService.dynamicPageLayout[this.pageId] === undefined) {
            this.getDynamicPageLayout(this.pageId);
        }
        else {
            this.setResult(this.itemsStateService.dynamicPageLayout[this.pageId], this.pageId);
        }

        this.subAsideTogglerClick();
        this.subSaveChanges();
        this.subMoveToNextTab();
    }

    private getDynamicPageLayout(pageId, isTabulatedPage = false) {
        this._dashboardCustomizationServiceProxy.getDynamicPageLayout(pageId)
            .pipe(
                catchError(() => {
                    this.gridIsLoading(false);
                    this.cd.markForCheck();
                    return of(undefined);
                })
            )
            .subscribe(result => {
                this.setResult(result, pageId, isTabulatedPage);
                this.itemsStateService.dynamicPageLayout[pageId] = result;
            });
    }

    setResult(result, pageId, isTabulatedPage = false): void {
        if (result !== undefined && result.items !== null && result.items !== undefined) {
            result.items.forEach(item => {
                item = this.formatItemDate(item);
            });

            this.pageLayout = result.items[0];

            if (this.pageLayout?.gridType) {
                this.selectedGridType = this.pageLayout.gridType;
            }
            this.fixedColWidth = this.pageLayout.fixedColWidth;

            if (this.pageLayout?.rowHeightRatio !== 0) {
                this.rowHeightRatio = this.pageLayout.rowHeightRatio;
            }

            if (this.pageLayout?.margin !== undefined || this.pageLayout?.margin !== null) {
                this.margin = this.pageLayout.margin;
            }

            if (this.pageLayout?.fixedRowHeight !== 0) {
                if (this.pageLayout?.fixedRowHeight) {
                    this.fixedRowHeight = this.pageLayout.fixedRowHeight;
                }
            }

            if (result.componentSettings?.pageLabelKey !== undefined) {
                this.pageLabelKey = result.componentSettings?.pageLabelKey;
                this.getDynamicPageLabelKey.emit(result.componentSettings?.pageLabelKey);
            }
            else {
                this.pageLabelKey = this.pageLayout?.dynamicPageLabelKey;
                this.getDynamicPageLabelKey.emit(this.pageLayout?.dynamicPageLabelKey);
            }

            if (result.componentSettings?.useStepper !== undefined) {
                this.useStepper = result.componentSettings?.useStepper;
            }

            if (this.pageLayout?.isPublic !== undefined) {
                this.isPublic = this.pageLayout?.isPublic;
            }

            this.clientAndErpService.clientId = this.pageLayout.clientId;
            this.clientAndErpService.erpId = this.pageLayout.erpId;
            this.getClientIdAndErpIdEmitter.emit({ clientId: this.pageLayout.clientId, erpId: this.pageLayout.erpId });

            if (isTabulatedPage) {
                this.widgetViewDefinitions = [];
                for (var currentTabComponent of this.tabComponents) {
                    if (currentTabComponent.gridStructures !== undefined) {
                        currentTabComponent.gridStructures = [];
                    }

                    if (currentTabComponent.formStructures !== undefined) {
                        currentTabComponent.formStructures = [];
                    }

                    if (currentTabComponent.actionButtonStructures !== undefined) {
                        currentTabComponent.actionButtonStructures = [];
                    }
                }

                var retrievedTab = this.tabComponents.find(x => x.id === pageId);
                var item = result.items[0];

                if (item?.status === true && retrievedTab.id === item.id) {
                    if (item?.attachedDynamicGridStructures !== undefined && item?.attachedDynamicGridStructures.length > 0) {
                        retrievedTab.gridStructures.push(...item?.attachedDynamicGridStructures?.filter(x => x.componentSettings.status && x.componentSettings.attachedStatus));
                    }

                    if (item?.attachedDynamicFormStructures !== undefined && item?.attachedDynamicFormStructures.length > 0) {
                        retrievedTab.formStructures.push(...item?.attachedDynamicFormStructures?.filter(x => x.componentSettings.status && x.componentSettings.attachedStatus));
                    }

                    if (item?.attachedDynamicActionButtonStructures !== undefined && item?.attachedDynamicActionButtonStructures.length > 0) {
                        retrievedTab.actionButtonStructures.push(...item?.attachedDynamicActionButtonStructures?.filter(x => x.componentSettings.status && x.componentSettings.attachedStatus));
                    }
                }
            }
            else {
                if ((result.componentSettings === undefined || result.componentSettings === null) && result.items[0].length === undefined) {
                    this.gridStructures = result.items[0]?.attachedDynamicGridStructures?.filter(x => x.componentSettings.status && x.componentSettings.attachedStatus);
                    this.formStructures = result.items[0]?.attachedDynamicFormStructures?.filter(x => x.componentSettings.status && x.componentSettings.attachedStatus);
                    this.actionButtonStructures = result.items[0]?.attachedDynamicActionButtonStructures?.filter(x => x.componentSettings.status && x.componentSettings.attachedStatus);
                    this.tabComponents = [];
                }
                else {
                    this.tabComponents = [];
                    this.gridStructures = [];
                    this.formStructures = [];
                    this.actionButtonStructures = [];

                    for (var item of result.items[0]) {
                        if (item?.status === true) {
                            var tabComponent = {
                                id: item.id,
                                componentSettings: result.componentSettings,
                                tabName: this.l(item.dynamicPageLabelKey),
                                gridStructures: [],
                                formStructures: [],
                                actionButtonStructures: []
                            };

                            if (item?.attachedDynamicGridStructures !== undefined && item?.attachedDynamicGridStructures.length > 0) {
                                tabComponent.gridStructures.push(...item?.attachedDynamicGridStructures?.filter(x => x.componentSettings.status && x.componentSettings.attachedStatus));
                            }

                            if (item?.attachedDynamicFormStructures !== undefined && item?.attachedDynamicFormStructures.length > 0) {
                                tabComponent.formStructures.push(...item?.attachedDynamicFormStructures?.filter(x => x.componentSettings.status && x.componentSettings.attachedStatus));
                            }

                            if (item?.attachedDynamicActionButtonStructures !== undefined && item?.attachedDynamicActionButtonStructures.length > 0) {
                                tabComponent.actionButtonStructures.push(...item?.attachedDynamicActionButtonStructures?.filter(x => x.componentSettings.status && x.componentSettings.attachedStatus));
                            }

                            this.tabComponents.push(tabComponent);
                        }
                    }

                    this.buildStepper();
                }
            }

            this.retrieveComponents();

            this.getUserDashboard(pageId, isTabulatedPage);
        }
    }

    private retrieveComponents() {
        this.formStructures.forEach(formStructure => {
            this.widgetViewDefinitions.push(
                new WidgetViewDefinition(
                    "Form_" + formStructure.componentSettings.formId + "_" + formStructure.componentSettings.formName.replace(/\s/g, '') + "_" + formStructure.componentSettings.formLabelKey.replace(/(?:^|\.?)([A-Z])/g, function (x, y) { return "_" + y; }).replace(/^_/, ""),
                    DynamicFormComponent,
                    {
                        formStructure: formStructure,
                        formId: formStructure.componentSettings.formId,
                        useDefaultActionButton: false,
                        fieldLabelWidth: formStructure.componentSettings.fieldLabelWidth,
                        fieldControlWidth: formStructure.componentSettings.fieldControlWidth,
                        groupFieldSetWidth: formStructure.componentSettings.groupFieldSetWidth,
                        groupFieldSetGap: formStructure.componentSettings.groupFieldSetGap,
                        showBorder: formStructure.componentSettings.showBorder,
                        showGuide: formStructure.componentSettings.showGuide,
                        isDetailPage: this.isDetailPage,
                        formIdentifier: this.formIdentifier,
                        formData: this.changesInTabs[formStructure.componentSettings.formId],
                        isPublic: this.isPublic,
                        otherData: this.savedChangesInTabs
                    }
                )
            );
        });

        this.gridStructures.forEach(gridStructure => {
            this.widgetViewDefinitions.push(
                new WidgetViewDefinition(
                    "Grid_" + gridStructure.componentSettings.gridId + "_" + gridStructure.componentSettings.gridName.replace(/\s/g, '') + "_" + gridStructure.componentSettings.gridLabelKey.replace(/(?:^|\.?)([A-Z])/g, function (x, y) { return "_" + y; }).replace(/^_/, ""),
                    DynamicGridComponent,
                    {
                        gridStructure: gridStructure,
                        gridId: gridStructure.componentSettings.gridId,
                        allowAdd: gridStructure.componentSettings.enableAddRecord,
                        reorderable: false,
                        groupable: false,
                        attachedRedirectPageId: gridStructure.componentSettings.attachedRedirectPageId,
                        masterPageId: this.masterPageId,
                        masterPageLabelKey: this.masterPageLabelKey,
                        isDetailPage: this.isDetailPage,
                        formIdentifier: this.formIdentifier,
                        pageLabelKey: this.pageLabelKey,
                        isDependentChildGrid: this.gridStructures.findIndex(x => x.componentSettings.dependentDynamicGridId === gridStructure.componentSettings.gridId) > -1,
                        additionalGridData: this.changesInTabs[gridStructure.componentSettings.gridId],
                        otherData: this.savedChangesInTabs
                    }
                )
            );
        });

        this.actionButtonStructures.forEach(actionButtonStructure => {
            this.widgetViewDefinitions.push(
                new WidgetViewDefinition(
                    "ActionButton_" + actionButtonStructure.componentSettings.actionButtonId + "_" + actionButtonStructure.componentSettings.actionButtonName.replace(/\s/g, '') + "_" + actionButtonStructure.componentSettings.actionButtonLabelKey.replace(/(?:^|\.?)([A-Z])/g, function (x, y) { return "_" + y; }).replace(/^_/, ""),
                    DynamicActionButtonComponent,
                    {
                        actionButtonStructure: actionButtonStructure,
                        dataSourceName: "dataSourceName"
                    },
                    {},
                    1,
                    2
                )
            );
        });

        this.tabComponents.forEach(tabComponent => {
            tabComponent.formStructures.forEach(formStructure => {
                this.widgetViewDefinitions.push(
                    new WidgetViewDefinition(
                        "Form_" + formStructure.componentSettings.formId + "_" + formStructure.componentSettings.formName.replace(/\s/g, '') + "_" + formStructure.componentSettings.formLabelKey.replace(/(?:^|\.?)([A-Z])/g, function (x, y) { return "_" + y; }).replace(/^_/, ""),
                        DynamicFormComponent,
                        {
                            formStructure: formStructure,
                            formId: formStructure.componentSettings.formId,
                            useDefaultActionButton: false,
                            fieldLabelWidth: formStructure.componentSettings.fieldLabelWidth,
                            fieldControlWidth: formStructure.componentSettings.fieldControlWidth,
                            groupFieldSetWidth: formStructure.componentSettings.groupFieldSetWidth,
                            groupFieldSetGap: formStructure.componentSettings.groupFieldSetGap,
                            showBorder: formStructure.componentSettings.showBorder,
                            showGuide: formStructure.componentSettings.showGuide,
                            isDetailPage: this.isDetailPage,
                            formIdentifier: this.formIdentifier,
                            formData: this.changesInTabs[formStructure.componentSettings.formId],
                            isPublic: this.isPublic,
                            otherData: this.savedChangesInTabs
                        }
                    )
                );
            });

            tabComponent.gridStructures.forEach(gridStructure => {
                this.widgetViewDefinitions.push(
                    new WidgetViewDefinition(
                        "Grid_" + gridStructure.componentSettings.gridId + "_" + gridStructure.componentSettings.gridName.replace(/\s/g, '') + "_" + gridStructure.componentSettings.gridLabelKey.replace(/(?:^|\.?)([A-Z])/g, function (x, y) { return "_" + y; }).replace(/^_/, ""),
                        DynamicGridComponent,
                        {
                            gridStructure: gridStructure,
                            gridId: gridStructure.componentSettings.gridId,
                            allowAdd: gridStructure.componentSettings.enableAddRecord,
                            reorderable: false,
                            groupable: false,
                            attachedRedirectPageId: gridStructure.componentSettings.attachedRedirectPageId,
                            masterPageId: this.masterPageId,
                            masterPageLabelKey: this.masterPageLabelKey,
                            isDetailPage: this.isDetailPage,
                            formIdentifier: this.formIdentifier,
                            pageLabelKey: this.pageLabelKey,
                            isDependentChildGrid: this.gridStructures.findIndex(x => x.componentSettings.dependentDynamicGridId === gridStructure.componentSettings.gridId) > -1,
                            additionalGridData: this.changesInTabs[gridStructure.componentSettings.gridId],
                            otherData: this.savedChangesInTabs
                        }
                    )
                );
            });

            tabComponent.actionButtonStructures.forEach(actionButtonStructure => {
                this.widgetViewDefinitions.push(
                    new WidgetViewDefinition(
                        "ActionButton_" + actionButtonStructure.componentSettings.actionButtonId + "_" + actionButtonStructure.componentSettings.actionButtonName.replace(/\s/g, '') + "_" + actionButtonStructure.componentSettings.actionButtonLabelKey.replace(/(?:^|\.?)([A-Z])/g, function (x, y) { return "_" + y; }).replace(/^_/, ""),
                        DynamicActionButtonComponent,
                        {
                            actionButtonStructure: actionButtonStructure,
                            disabled: false,
                            dataSourceName: "dataSourceName"
                        },
                        {},
                        1,
                        2
                    )
                );
            });
        });
    }

    private getUserDashboard(pageId, isTabulatedPage) {
        if (!isTabulatedPage) {
            this.options = [];
            //gridster options for new page
            if (this.tabComponents.length > 0) {
                for (var i = 0; i < this.tabComponents.length; i++) {
                    this.options.push(this.getGridsterConfig());
                }
            }
            else {
                this.options.push(this.getGridsterConfig());
            }
        }

        this._dashboardViewConfiguration.WidgetViewDefinitions = this._dashboardViewConfiguration.WidgetViewDefinitions.filter(
            x => this.widgetViewDefinitions.findIndex(y => x.id === y.id) < 0)
        this._dashboardViewConfiguration.WidgetViewDefinitions = this._dashboardViewConfiguration.WidgetViewDefinitions.concat(this.widgetViewDefinitions);

        if (!isTabulatedPage) {
            this.initializeUserDashboardDefinition();
        }
        else {
            for (let page of this.userDashboard.pages) {
                page.widgets = [];
            }

            let pageToUpdate = this.userDashboard.pages.find(x => x.id === pageId);
            let updatedTab = this.tabComponents.find(x => x.id === pageId);
            pageToUpdate.widgets = this.retrieveWidgets(true, updatedTab);
        }

        if (!isTabulatedPage) {
            //select first page (if user delete all pages server will add default page to userDashboard.)
            this.selectedPage = {
                idx: 0,
                id: this.userDashboard.pages[0].id,
                name: this.userDashboard.pages[0].name
            };
        }

        this.loading = false;
        this.busy = false;

        this.cd.markForCheck();
    }

    retrieveChanges = () => {
        this.dynamicComponents.forEach(dynamicComponent => {
            var componentInstance = dynamicComponent.componentRef.instance;
            if (componentInstance.id === "DynamicFormComponent") {
                this.changesInTabs[componentInstance.formId] = componentInstance.dynamicForm.value;
            }
            else if (componentInstance.id === "DynamicGridComponent") {
                this.gridNameByComponent[componentInstance.gridId] = componentInstance.gridSettings.gridName;
                this.changesInTabs[componentInstance.gridId] = componentInstance.addedItems.concat(componentInstance.updatedItems);
            }
        });
    }

    moveToNextTab = (pageNumber = 0) => {
        this.selectPageTab("", pageNumber);
    }

    clearComponentChanges = (disableTab = false, saveTriggered = false) => {
        // This will allow access to different properties from other tabs for the currently selected tab.
        for (let componentId in this.changesInTabs) {
            if (Array.isArray(this.changesInTabs[componentId])) {
                this.savedChangesInTabs[this.gridNameByComponent[componentId]] = this.changesInTabs[componentId];
            }
            else {
                for (let key in this.changesInTabs[componentId]) {
                    this.savedChangesInTabs[key] = this.changesInTabs[componentId][key];
                }
            }
        }

        if (disableTab) {
            this.setToTrue = disableTab;
        }
        else if (saveTriggered) {
            this.setToTrue = false;
        }

        // The next step of the stepper will be enabled once the current tab's changes are saved.
        if (!this.setToTrue) {
            if (this.selectedPage.idx + 1 < this.steps.length) {
                this.steps[this.selectedPage.idx + 1].disabled = disableTab;
            }
        }
        else {
            if (this.selectedPage.idx + 1 < this.steps.length) {
                this.steps[this.selectedPage.idx + 1].disabled = this.setToTrue;
            }
        }

        this.changesInTabs = {};
    }

    selectPageTab(pageId: string, pageNumber: number): void {
        this.setToTrue = false;
        this.componentList = [];
        this.retrieveChanges();

        if (pageId) {
            let pageIndex = this.userDashboard.pages.findIndex(page => page.id === pageId);
            this.selectedPage = {
                idx: pageIndex,
                id: pageId,
                name: this.userDashboard.pages[pageIndex].name
            };

            this.dashboardTabs.tabs[pageIndex].active = true;
            this.appSession.stepNumber = pageIndex;
        }
        else {
            this.selectedPage = {
                idx: pageNumber,
                id: this.userDashboard.pages[pageNumber].id,
                name: this.userDashboard.pages[pageNumber].name
            };

            this.dashboardTabs.tabs[pageNumber].active = true;
            this.appSession.stepNumber = pageNumber;
        }

        this.getDynamicPageLayout(pageId != "" ? pageId : this.selectedPage.id, true);

        //when tab change gridster should redraw because if a tab is not active gridster think that its height is 0 and do not draw it.
        this.options.forEach(option => {
            if (option.api) {
                option.api.optionsChanged();
            }
        });
    }

    public currentStepChange(e: number): void {
        this.selectPageTab("", e);
    }

    moreThanOnePage(): boolean {
        return this.userDashboard && this.userDashboard.pages && this.userDashboard.pages.length > 1;
    }

    hideTabs(): boolean {
        if (this.moreThanOnePage()) {
            return this.useStepper;
        }
        else {
            return true;
        }
    }

    retrieveInputs(inputs, component) {
        let inputsValue = inputs;

        if (component.id === 'DynamicActionButtonComponent') {
            inputsValue.dynamicGridComponents = [];
            inputsValue.dynamicFormComponents = [];
            // TODO: Assign based on connection between buttons, grids and forms
            for (var componentValue of this.componentList) {
                if (componentValue.instance instanceof DynamicGridComponent && componentValue.instance.gridId !== undefined) {
                    inputsValue.dynamicGridComponents.push(componentValue.instance);
                }
                else if (componentValue.instance instanceof DynamicFormComponent && componentValue.instance.formId !== undefined) {
                    if (inputsValue.dynamicFormComponents.length > 0) {
                        let formIndex = inputsValue.dynamicFormComponents.findIndex(x => x.formId === componentValue.instance.formId);
                        if (formIndex > -1) {
                            inputsValue.dynamicFormComponents.splice(formIndex, 1);
                        }
                    }

                    inputsValue.dynamicFormComponents.push(componentValue.instance);
                }
            }
        }

        return inputsValue;
    }

    private getGridsterConfig(): GridsterConfig {
        const isRtl = rtlDetect.isRtlLang(abp.localization.currentLanguage.name);
        return {
            pushItems: false,
            pushResizeItems: false,
            draggable: {
                enabled: false
            },
            resizable: {
                enabled: false
            },
            rowHeightRatio: this.rowHeightRatio,
            fixedRowHeight: this.fixedRowHeight,
            fixedColWidth: this.fixedColWidth,
            margin: this.margin,
            outerMarginRight: 15,
            maxCols: 100,
            maxRows: 100,
            gridType: this.selectedGridType,
            dirType: isRtl ? 'rtl' : 'ltr'
        };
    }

    private getWidgetViewDefinition(id: string): WidgetViewDefinition {
        return this._dashboardViewConfiguration.WidgetViewDefinitions.find(widget => widget.id === id);
    }

    retrievePages() {
        let pages = [];

        if (this.tabComponents !== undefined && this.tabComponents.length > 0) {
            let index = 0;
            for (let tabComponent of this.tabComponents) {
                pages.push({
                    id: tabComponent.id,
                    name: tabComponent.tabName,
                    active: index == this.current,
                    widgets: this.retrieveWidgets(true, tabComponent)
                });
            }

            return pages;
        }
        else {
            return [{
                id: this.pageLayout.id,
                name: this.pageLayout.dynamicPageName,
                widgets: this.retrieveWidgets()
            }];
        }
    }

    private retrieveWidgets(useTabComponents = false, tabComponent = undefined) {
        let widgets = [];
        let gridStructures = useTabComponents && tabComponent ? tabComponent.gridStructures : this.gridStructures;
        let formStructures = useTabComponents && tabComponent ? tabComponent.formStructures : this.formStructures;
        let actionButtonStructures = useTabComponents && tabComponent ? tabComponent.actionButtonStructures : this.actionButtonStructures;

        for (let formStructure of formStructures) {
            let widgetId = "Form_" + formStructure.componentSettings.formId + "_" + formStructure.componentSettings.formName.replace(/\s/g, '') + "_" + formStructure.componentSettings.formLabelKey.replace(/(?:^|\.?)([A-Z])/g, function (x, y) { return "_" + y; }).replace(/^_/, "");
            widgets.push({
                id: widgetId,
                name: formStructure.componentSettings.formName,
                component: this.getWidgetViewDefinition(widgetId).component,
                inputs: this.getWidgetViewDefinition(widgetId).inputs,
                outputs: this.getWidgetViewDefinition(widgetId).outputs,
                type: "form",
                gridInformation: {
                    id: widgetId,
                    cols: formStructure.componentSettings.positionDetails.widgetWidth,
                    rows: formStructure.componentSettings.positionDetails.widgetHeight,
                    x: formStructure.componentSettings.positionDetails.widgetPositionX,
                    y: formStructure.componentSettings.positionDetails.widgetPositionY
                }
            });
        }

        for (let gridStructure of gridStructures) {
            let widgetId = "Grid_" + gridStructure.componentSettings.gridId + "_" + gridStructure.componentSettings.gridName.replace(/\s/g, '') + "_" + gridStructure.componentSettings.gridLabelKey.replace(/(?:^|\.?)([A-Z])/g, function (x, y) { return "_" + y; }).replace(/^_/, "");
            widgets.push({
                id: widgetId,
                name: gridStructure.componentSettings.gridName,
                component: this.getWidgetViewDefinition(widgetId).component,
                inputs: this.getWidgetViewDefinition(widgetId).inputs,
                outputs: this.getWidgetViewDefinition(widgetId).outputs,
                type: "grid",
                gridInformation: {
                    id: widgetId,
                    cols: gridStructure.componentSettings.positionDetails.widgetWidth,
                    rows: gridStructure.componentSettings.positionDetails.widgetHeight,
                    x: gridStructure.componentSettings.positionDetails.widgetPositionX,
                    y: gridStructure.componentSettings.positionDetails.widgetPositionY
                }
            });
        }

        for (let actionButtonStructure of actionButtonStructures) {
            let widgetId = "ActionButton_" + actionButtonStructure.componentSettings.actionButtonId + "_" + actionButtonStructure.componentSettings.actionButtonName.replace(/\s/g, '') + "_" + actionButtonStructure.componentSettings.actionButtonLabelKey.replace(/(?:^|\.?)([A-Z])/g, function (x, y) { return "_" + y; }).replace(/^_/, "");
            widgets.push({
                id: widgetId,
                name: actionButtonStructure.componentSettings.actionButtonName,
                component: this.getWidgetViewDefinition(widgetId).component,
                inputs: this.getWidgetViewDefinition(widgetId).inputs,
                outputs: this.getWidgetViewDefinition(widgetId).outputs,
                type: "actionbutton",
                gridInformation: {
                    id: widgetId,
                    cols: actionButtonStructure.componentSettings.positionDetails.widgetWidth,
                    rows: actionButtonStructure.componentSettings.positionDetails.widgetHeight,
                    x: actionButtonStructure.componentSettings.positionDetails.widgetPositionX,
                    y: actionButtonStructure.componentSettings.positionDetails.widgetPositionY
                }
            });
        }

        return widgets;
    }

    initializeUserDashboardDefinition() {
        this.userDashboard = {
            dashboardName: this.dashboardName,
            filters: [],
            pages: this.retrievePages()
        };
    }

    componentCreated(compRef: ComponentRef<any>) {
        this.componentList.push(compRef);
    }

    ngDoCheck() {
    }

    ngAfterContentInit() {
    }

    ngAfterContentChecked() {
    }

    ngAfterViewInit() {
        this.addCustomTemplates();
    }

    ngAfterViewChecked() {
        if (!this.currentUpdated && this.dashboardTabs !== undefined) {
            if (this.dashboardTabs.tabs[this.current] !== undefined) {
                this.dashboardTabs.tabs[this.current].active = true;
                this.currentUpdated = true;
            }
        }
    }

    ngOnDestroy() {
        this.unSubAsideTogglerClick();
        this.unSubSaveChanges();
        this.unSubMoveToNextTab();
    }

    addCustomTemplates() {
    }

    cellClick($event) {
    }

    getData($event): void {
        this.dataAsOf = $event.dataAsOf;
    }

    saveDynamicForm(): void {

    }

    refreshAllGrids(): void {
        if (this.options) {
            this.options.forEach(option => {
                option.draggable.enabled = false;
                option.resizable.enabled = false;
                option.api.optionsChanged();
            });
        }
    }

    subAsideTogglerClick() {
        abp.event.on('app.kt_aside_toggler.onClick', this.onMenuToggle);
    }

    unSubAsideTogglerClick() {
        abp.event.off('app.kt_aside_toggler.onClick', this.onMenuToggle);
    }

    onMenuToggle = () => {
        this.refreshAllGrids();
    }

    subSaveChanges() {
        abp.event.on('app.clearComponentChanges', this.clearComponentChanges);
    }

    unSubSaveChanges() {
        abp.event.off('app.clearComponentChanges', this.clearComponentChanges);
    }

    subMoveToNextTab() {
        abp.event.on('app.moveToNextTab', this.moveToNextTab);
    }

    unSubMoveToNextTab() {
        abp.event.off('app.moveToNextTab', this.moveToNextTab);
    }
}
