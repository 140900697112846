import { Component, Injector, Input } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'custom-kendo-pager-info',
    templateUrl: './custom-kendo-pager-info.component.html',
    styleUrls: ['./custom-kendo-pager-info.component.scss']
})
export class CustomKendoPagerInfoComponent extends AppComponentBase {
    @Input() hasGridFilter: boolean;
    @Input() filteredTotal: number;
    @Input() unfilteredTotal: number;

    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    getUnfilteredLocaleString() {
        return this.unfilteredTotal.toLocaleString();
    }
}
